$display-props: "flex", "inline-flex", "block", "inline-block";
$text-props: "center", "justify", "left", "right";
$justify-content-props: "space-between", "start", "end", "space-around";
$position-props : 'fixed' ,'static' ,'relative' ,  'absolute' , 'sticky';

$std-sizes: 1, 2, 3, 4, 5;
$sides: "top", "bottom", "left", "right";

@each $display-props in $display-props {
    .d-#{$display-props} {
        display: #{$display-props}!important;
    }
}
@each $pos-props in $position-props {
    .position-#{$pos-props} {
        position: #{$pos-props};
    }
}
@each $text-props in $text-props {
    .text-#{$text-props} {
        text-align: #{$text-props};
    }
}
@each $js-props in $justify-content-props {
    .justify-content-#{$js-props} {
        justify-content: #{$js-props};
    }
}


@each $size in $std-sizes {

   .px-#{$size} {
            padding-left: #{$size/4}em;
            padding-right: #{$size/4}em;
        }
        .py-#{$size} {
            padding-top: #{$size/4}em;
            padding-bottom: #{$size/4}em;
        }
        .p-#{$size} {
            padding: #{$size/4}em;
        }

           .mx-#{$size} {
            margin-left: #{$size/4}em;
            margin-right: #{$size/4}em;
        }
        .my-#{$size} {
            margin-top: #{$size/4}em;
            margin-bottom: #{$size/4}em;
        }
        .m-#{$size} {
            margin: #{$size/4}em;
        }
}

@each $size in $std-sizes {
    @each $side in $sides {
        .p#{str-slice($side ,0,1)}-#{$size} {
            padding-#{$side}: #{$size/4}em;
        }
     
        .m#{str-slice($side ,0,1)}-#{$size} {
            margin-#{$side}: #{$size/4}em;
        }
     
    }
}